@import 'variables';
@import 'anim';
@import 'form';
@import 'mobile-v2';

html,body {
  //height: 100%;
  overflow-x: hidden;
}

body {
  width:100%;
  font-family: CircularStd!important;
}
h1, h2, h3, h4, h5, h6{
  font-family: CircularStd;
  color:$primary-120;
}
p{
  color:$grey-darker;
  a{
    color: $primary;
  }
}




.logo-avatar{
  &.initials{
    display: inline-block;
    color:#FFFFFF;
    background: $primary;
    text-transform: capitalize;
    text-align: center;

  }
  &.sm{
    width: 45px;
    height: 45px;
    line-height: 40px;
    font-size: 20px;


  }
  &.xs{
    width: 25px;
    height: 25px;
    line-height: 22px;
    font-size: 14px;
    border:1px solid #fff;
    border-radius: 50%;

  }
}





footer{

  padding-top:75px;
  position:relative;
  overflow: hidden;
  background: $primary-dark;

  border-radius: 50px 50px 0 0;
}
