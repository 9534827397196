
form{
  label{
    color:$primary;
    max-width: 100%;
    font-weight: 700;
    &.required:after {
      content: " *";
      color:$primary-120;
    }
  }
  input[type="checkbox"]{
    /*margin:auto 5px;*/
  }
  legend{
    font-weight: 700;
  }

  .ui-datepicker-div{
    background: white;
  }


  input[type="text"],input[type="email"],input[type="date"], input[type="password"], input[type="number"], textarea, select{

    width: 100%;
    padding: 16px;
    border: 1px solid $secondary-lighter!important;
    border-radius: 20px!important;

    background: white;
    color: $grey-dark;
    &:focus{
      outline: none;
    }

  }

  textarea{
    min-height: 200px;
    padding: 5px;
    resize:none;
  }



  .custom-selector{

    .form-check{
      display: inline-block;
      padding:0;

      label{
        display: inline-block;
        color:$secondary-dark;
        cursor: pointer;
        font-size: 16px;
        font-weight: normal;
        border:1px solid $secondary;
        border-radius: 5px;
        margin:4px;
        vertical-align: middle;
        img{
          vertical-align: middle;
        }
        &:hover{
          color:#FFF;
          font-style: italic;
          background: $gradien-bg;

        }
        &.active{
          color:#FFF;
          text-transform: uppercase;
          font-style: italic;
          font-weight: 700;
          font-size: 13px;
          background: $primary-dark;
          &:hover{
            background: $primary;
          }
        }
      }
      input{
        opacity: 0.5;
        display:none;

      }
    }



    .form-check:not(.freelance-check){

      label:not(.checkbox):not(.radio){
        padding:0 11px;

        &::after, &::before{
          content:'';
          display: inline-block;

          height: 20px;
          margin-left:3px;
          padding:0 3px;

        }
        &:hover{
          padding:0 1px;
          &:after{
            content:' ❓';
            padding:0;
          }
        }

        &.active{
          padding:0 1px;
          &::after{
            content:'✔';
            padding:0;
          }
          &:hover{
            background: $primary-light;
          }
        }

      }

    }

  }

}
